import { ref, reactive } from "vue";
import c3api from "../c3api";
import { useRouter } from "vue-router/auto";
import { useToast } from "vue-toastification";
import { unwrapReactive } from "../utils/unwrapReactive";

export default function useWarehouses() {
	const warehousesLoading = ref(false);
	const warehouses = ref(null);
	const warehouse = ref(null);
	const dockDoors = ref(null);
	const toast = useToast();
	const dashboardData = ref(null);
	const defaultLocalState = reactive({});
	const router = useRouter();
	const warehouseIds = ref([]);

	const fetchWarehouses = async (localState = defaultLocalState) => {
		warehousesLoading.value = true;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get("/warehouses", { params });
			warehousesLoading.value = false;
			return (warehouses.value = response.data);
		} catch (error) {
			toast.error(error.response?.data?.data.join(". "));
		}
		warehousesLoading.value = false;
	};

	const fetchMyWarehouses = async (localState = defaultLocalState) => {
		warehousesLoading.value = true;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get("/my/warehouses", { params });
			warehousesLoading.value = false;
			return (warehouses.value = response.data);
		}	
		catch (error) {
			toast.error(error.response?.data?.data.join(". "));
		}
		warehousesLoading.value = false;
	};

	const fetchWarehouse = async (id, localState = defaultLocalState) => {
		warehousesLoading.value = true;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/warehouses/${id}`, { params });
			warehousesLoading.value = false;
			return (warehouse.value = response.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
			router.push("/404");
		}
		warehousesLoading.value = false;
	};

	const fetchWarehouseDockDoors = async (
		id,
		localState = defaultLocalState
	) => {
		warehousesLoading.value = true;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/warehouses/${id}/dock_doors`, {
				params,
			});
			warehousesLoading.value = false;
			return (dockDoors.value = response.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		}
		warehousesLoading.value = false;
	};

	const deleteDockDoor = async (dockDoor) => {
		warehousesLoading.value = true;
		const confirmation = confirm(
			`Are you sure you want to delete dock door ${dockDoor.name} (${dockDoor.nickname})?`
		);
		if (confirmation) {
			try {
				const response = await c3api.delete(`/dock_doors/${dockDoor.id}`);
				toast.success(
					`Dock door ${dockDoor.name} (${dockDoor.nickname}) deleted successfully`
				);
				fetchWarehouseDockDoors(dockDoor.warehouse_id);
			} catch (error) {
				console.error(error);
				toast.error(error.response.data.data.join(". "));
			}
		}
		warehousesLoading.value = false;
	};

	const restoreDockDoor = async (dockDoor) => {
		warehousesLoading.value = true;
		const confirmation = confirm(
			`Are you sure you want to restore dock door ${dockDoor.name} (${dockDoor.nickname})?`
		);
		if (confirmation) {
			try {
				const response = await c3api.patch(
					`/dock_doors/${dockDoor.id}/restore`
				);
				toast.success(
					`Dock door ${dockDoor.name} (${dockDoor.nickname}) restored successfully`
				);
				fetchWarehouseDockDoors(dockDoor.warehouse_id);
			} catch (error) {
				console.error(error);
				toast.error(error.response.data.data.join(". "));
			}
		}
		warehousesLoading.value = false;
	};

	const createWarehouse = async (params) => {
		warehousesLoading.value = true;
		try {
			const response = await c3api.post("/warehouses", params);
			toast.success(
				`Warehouse ${response.data.data.name} created successfully!`
			);
			warehousesLoading.value = false;
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
			warehousesLoading.value = false;
			return error.response;
		}
	};

	const editWarehouse = async (id, params) => {
		warehousesLoading.value = true;
		try {
			const response = await c3api.patch(`/warehouses/${id}`, params);
			toast.success(
				`Warehouse ${response.data.data.name} updated successfully!`
			);
			warehousesLoading.value = false;
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
		}
		warehousesLoading.value = false;
	};

	const manageIbSlot = async (id, params) => {
		warehousesLoading.value = true;
		try {
			const response = await c3api.patch(
				`/warehouses/${id}/manage_ib_slot`,
				params
			);
			toast.success(
				`Warehouse ${response.data.data.name} inbound slot updated successfully!`
			);
			warehousesLoading.value = false;
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
		}
		warehousesLoading.value = false;
	};

	const manageObSlot = async (id, params) => {
		warehousesLoading.value = true;
		try {
			const response = await c3api.patch(
				`/warehouses/${id}/manage_ob_slot`,
				params
			);
			toast.success(
				`Warehouse ${response.data.data.name} outbound slot updated successfully!`
			);
			warehousesLoading.value = false;
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
		}
		warehousesLoading.value = false;
	};

	const manageClosures = async (id, params) => {
		warehousesLoading.value = true;
		try {
			const response = await c3api.patch(
				`/warehouses/${id}/manage_closure`,
				params
			);
			toast.success(
				`Warehouse ${response.data.data.name} closures updated successfully!`
			);
			warehousesLoading.value = false;
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
		}
		warehousesLoading.value = false;
	};

	const removeClosure = async (id, params) => {
		warehousesLoading.value = true;
		try {
			const response = await c3api.patch(
				`/warehouses/${id}/remove_closure`,
				params
			);
			toast.success(
				`Warehouse ${response.data.data.name} closures updated successfully!`
			);
			warehousesLoading.value = false;
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
		}
		warehousesLoading.value = false;
	};

	const deleteWarehouse = async (warehouse) => {
		warehousesLoading.value = true;
		const confirmation = confirm(
			`Are you sure you want to delete warehouse ${warehouse.name}?`
		);
		if (confirmation) {
			try {
				const response = await c3api.delete(`/warehouses/${warehouse.id}`);
				toast.success(`Warehouse ${warehouse.name} deleted successfully`);
				warehousesLoading.value = false;
				return response.data;
			} catch (error) {
				console.error(error);
				toast.error(error.response.data.data.join(". "));
			}
		}
		warehousesLoading.value = false;
	};

	const fetchDashboardData = async (id, localState = defaultLocalState) => {
		warehousesLoading.value = true;
		try {
			const response = await c3api.get(`/warehouses/${id}/dashboard`);
			dashboardData.value = response.data;

			warehousesLoading.value = false;
			return response.data;
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		}
		warehousesLoading.value = false;
	};

	const transformSlotsData = (apptSettings) => {
		const combinedSlots = {};

		// Processing IB slots
		if (apptSettings?.ib_slots) {
			Object.keys(apptSettings.ib_slots).forEach((date) => {
				combinedSlots[date] = combinedSlots[date] || { ib: [], ob: [] };
				combinedSlots[date].ib = apptSettings?.ib_slots[date];
			});
		}

		// Processing OB slots
		if (apptSettings?.ob_slots) {
			Object.keys(apptSettings.ob_slots).forEach((date) => {
				combinedSlots[date] = combinedSlots[date] || { ib: [], ob: [] };
				combinedSlots[date].ob = apptSettings?.ob_slots[date];
			});
		}

		// Sort the keys (dates) and reconstruct the object
		const sortedKeys = Object.keys(combinedSlots).sort(
			(a, b) => new Date(a) - new Date(b)
		);
		const sortedCombinedSlots = {};
		sortedKeys.forEach((key) => {
			sortedCombinedSlots[key] = combinedSlots[key];
		});

		return sortedCombinedSlots;
	};

	function updatePage(newPage) {
		router.push({ query: { page: newPage } });
	}

	const getCachedWarehouseFilters = function (parentFilter) {
		const savedExternalFilters = window.localStorage.getItem(parentFilter);
		if (savedExternalFilters) {
			let filters = JSON.parse(savedExternalFilters);
			if (typeof filters.warehouseIds == "undefined") filters.warehouseIds = { 0: 1 };
			warehouseIds.value = filters.warehouseIds;
		} else {
			console.log("Not warehouse filters found in key: ", parentFilter);
		}
		return warehouseIds.value;
	};

	return {
		warehouse,
		warehouses,
		warehouseIds,
		fetchWarehouses,
		fetchMyWarehouses,
		fetchWarehouse,
		createWarehouse,
		editWarehouse,
		deleteWarehouse,
		updatePage,
		fetchWarehouseDockDoors,
		deleteDockDoor,
		manageIbSlot,
		manageObSlot,
		manageClosures,
		removeClosure,
		fetchDashboardData,
		dashboardData,
		dockDoors,
		warehousesLoading,
		transformSlotsData,
		restoreDockDoor,
		getCachedWarehouseFilters
	};
}
